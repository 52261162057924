
import { defineComponent, onMounted, reactive, ref } from "vue";
import { translate } from "@/core/services/HelpersFun";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import userInfo from "@/core/services/UserInfo";
export default defineComponent({
  name: "help",
  setup() {
    const user = userInfo.getUser();
    const tutorials = reactive([
      // {
      //   id: 1,
      //   url: "https://inboxino.com/media/panel-help.mp4",
      //   title: translate("Teaching how to use the Inboxino panel"),
      //   desc: "",
      //   link: "",
      // },
      // {
      //   id: 2,
      //   url: "https://inboxino.com/media/buy.mp4",
      //   title: translate(
      //     "Learning how to connect to the messenger and buy a subscription"
      //   ),
      //   desc: "",
      //   link: "/bot-connections",
      // },
      // {
      //   id: 4,
      //   url: "https://inboxino.com/media/send-message.mp4",
      //   title: translate("Training to send bulk messages"),
      //   desc: "",
      //   link: "/services/bulk-message",
      // },
      // {
      //   id: 5,
      //   url: "https://inboxino.com/media/api.mp4",
      //   title: translate("Teaching web service connection"),
      //   desc: "",
      //   link: "/services/api",
      // },
      // {
      //   id: 6,
      //   url: "https://inboxino.com/media/secretary.mp4",
      //   title: translate("Autoresponder"),
      //   desc: "",
      //   link: "/services/Secretary",
      // },
      // {
      //   id: 7,
      //   url: "https://inboxino.com/media/cooperation.mp4",
      //   title: translate("Cooperation training in sales"),
      //   desc: "",
      //   link: "/cooperationInSales",
      // },
      // {
      //   id: 8,
      //   url: "https://inboxino.com/media/send-rules.mp4",
      //   title: translate("Rules of sending messages"),
      //   desc: "",
      //   link: "/services/bulk-message",
      // },
    ]);

    const aparat_videos = reactive([
      {
      title : "آموزش تهیه اشتراک اینباکسینو",
      src : "https://www.aparat.com/video/video/embed/videohash/F4ubW/vt/frame?titleShow=true"
    },
     {
      title : "آموزش اتصال پیام‌رسان‌ها در اینباکسینو",
      src : "https://www.aparat.com/video/video/embed/videohash/Y0sVM/vt/frame?titleShow=true"
    },
     {
      title : "آموزش پاسخگوی خودکار اینباکسینو",
      src : "https://www.aparat.com/video/video/embed/videohash/y0hb7/vt/frame?titleShow=true"
    },
     {
      title : "آموزش ارسال پیام انبوه اینباکسینو",
      src : "https://www.aparat.com/video/video/embed/videohash/wAK56/vt/frame?titleShow=true"
    },
     
    ]);
    onMounted(() => {
      setCurrentPageBreadcrumbs(translate("Help and training"), []);
    });

    return {
      tutorials,
      translate,
      user,
      aparat_videos,
    };
  },
});
